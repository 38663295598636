@import "variables.scss";

@mixin font($size: null, $color: null, $lineHeight: null, $letterSpacing: null, $family: secondary, $weight: regular) {
  @if $family == secondary{
    @if $weight == light {
      font-family: map-get($font-family, secondary-light);
    } @else if $weight == regular {
      font-family: map-get($font-family, $family);
    } @else if $weight == medium {
      font-family: map-get($font-family, secondary-medium);
    } @else if $weight == semibold {
      font-family: map-get($font-family, secondary-semibold);
    } @else if $weight == bold {
      font-family: map-get($font-family, secondary-bold);
    }
    font-weight: 400;
  } @else {
    font-family: map-get($font-family, $family);
    @if $weight == light {
      font-weight: 300;
    } @else if $weight == regular {
      font-weight: 400;
    } @else if $weight == medium {
      font-weight: 500;
    } @else if $weight == semibold {
      font-weight: 600;
    } @else if $weight == bold {
      font-weight: 700;
    } @else {
      font-weight: $weight;
    }
  }
  @if $size {
    font-size: $size;
  }
  @if $color {
    color: map-get($colors, $color);
  }
  @if $lineHeight {
    line-height: $lineHeight;
  }
  @if $letterSpacing {
    letter-spacing: $letterSpacing;
  }
}

@mixin row($horizontal: flex-start, $vertical: flex-start) {
  display: flex;
  flex-direction: row;
  justify-content: $horizontal;
  align-items: $vertical;
}

@mixin column($horizontal: flex-start, $vertical: flex-start) {
  display: flex;
  flex-direction: column;
  justify-content: $vertical;
  align-items: $horizontal;
}

@mixin for-size($size) {
  @if $size == mobile {
    @media (max-width: $mobileWidth) { @content; }
  } @else if $size == desktop {
    @media (min-width: $desktopWidth) { @content; }
  } @else if $size == wide {
    @media (min-width: $wideWidth) { @content; }
  } @else {
    @media (max-width: $size) { @content; }
  }
}
