/* Reset */

html, body, div, span, applet, object, iframe, blockquote, pre, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* fonts */
@font-face {
  font-family: "DomaineDisplay";
  src: url("../assets/fonts/DomaineDisplay-Regular.otf");
}

@font-face {
  font-family: "DomaineDisplayItalic";
  src: url("../assets/fonts/DomaineDisplay-RegularItalic.otf");
}

@font-face {
  font-family: 'HKGrotesk-SemiBold';
  src: url('../assets/fonts/HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/HKGrotesk-SemiBold.otf')  format('opentype'),
	     url('../assets/fonts/HKGrotesk-SemiBold.woff') format('woff'), url('../assets/fonts/HKGrotesk-SemiBold.ttf')  format('truetype'), url('../assets/fonts/HKGrotesk-SemiBold.svg#HKGrotesk-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk-Bold';
  src: url('../assets/fonts/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/HKGrotesk-Bold.otf')  format('opentype'),
	     url('../assets/fonts/HKGrotesk-Bold.woff') format('woff'), url('../assets/fonts/HKGrotesk-Bold.ttf')  format('truetype'), url('../assets/fonts/HKGrotesk-Bold.svg#HKGrotesk-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('../assets/fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/HKGrotesk-Regular.otf')  format('opentype'),
	     url('../assets/fonts/HKGrotesk-Regular.woff') format('woff'), url('../assets/fonts/HKGrotesk-Regular.ttf')  format('truetype'), url('../assets/fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk-Medium';
  src: url('../assets/fonts/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/HKGrotesk-Medium.otf')  format('opentype'),
	     url('../assets/fonts/HKGrotesk-Medium.woff') format('woff'), url('../assets/fonts/HKGrotesk-Medium.ttf')  format('truetype'), url('../assets/fonts/HKGrotesk-Medium.svg#HKGrotesk-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk-Light';
  src: url('../assets/fonts/HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/HKGrotesk-Light.otf')  format('opentype'),
	     url('../assets/fonts/HKGrotesk-Light.woff') format('woff'), url('../assets/fonts/HKGrotesk-Light.ttf')  format('truetype'), url('../assets/fonts/HKGrotesk-Light.svg#HKGrotesk-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Main styles */
html {
  font-family: 'HKGrotesk', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #333;
}

div {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  font-family: 'HKGrotesk', sans-serif;
  font-size: 16px;
  line-height: 24px;
}

h1 { 
  font-family: 'HKGrotesk-Bold', sans-serif;
  font-size: 32px;
  line-height: 34px;
}

h2 {
  font-family: 'HKGrotesk-Bold', sans-serif;
  font-size: 26px;
  line-height: 28px;
}

h3 {
  font-family: 'HKGrotesk-Bold', sans-serif;
  font-size: 20px;
  line-height: 22px;
}

